import React from 'react';
import PropTypes from 'prop-types';

import { Layout } from '../../components';
import { useTranslations } from '../../hooks';

import HeroSection from './hero-section';
import ContentSection from './content-section';

import styles from './About.module.css';
import GoalsSection from './goals-section/GoalsSection';

export const About = ({
    data: {
        contentful: {
            aboutPageCollection: {
                items: [page],
            },
        },
    },
    location,
}) => {
    const translations = useTranslations();

    const {
        seo,
        goals,
        title,
        mission,
        introBlocks,
        ceCertification,
        galleryCollection: {
            items: galleryPhotos,
        },
    } = page;

    const seoData = {
        title: `${seo.title} - Agostinho & João Pinto, Lda`,
        description: seo.description,
        image: seo.image?.url,
    };

    return (
        <Layout
            seoData={ seoData }
            location={ location }
            className={ styles.main }>
            <h1 className={ styles.heading }>{ seo.title }</h1>
            <HeroSection
                title={ title }
                photos={ galleryPhotos }
                blocksData={ {
                    blocks: introBlocks,
                    title: translations.aboutUs,
                } } />
            <GoalsSection
                goals={ goals }
                title={ translations.goal }
                className={ styles.contentSection } />
            <ContentSection
                text={ mission }
                title={ translations.mission }
                className={ styles.contentSection } />
            <ContentSection
                text={ ceCertification }
                className={ styles.contentSection }
                title={ translations.ceCertification } />
        </Layout>
    );
};

About.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            aboutPageCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default About;
