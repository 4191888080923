/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BaseCarousel from '@moxy/react-carousel';

import { useViewport } from '../../../../hooks';
import { isTouchDevice } from '../../../../utils';
import { Arrow, ContentfulImage } from '../../../../components';

import styles from './Carousel.module.css';

const SLIDE_TRANSITION_DURATION = 300;
const SLIDE_SNAP_DURATION = 150;
const OFFSET = {
    TABLET: 60,
    MOBILE: 30,
};

export const Carousel = ({
    photos,
    className,
    ...remainingProps
}) => {
    const { isTablet, isMobile } = useViewport();
    const offset = isTablet ? OFFSET.TABLET : (isMobile ? OFFSET.MOBILE : 0);

    const renderArrows = ({
        next,
        current,
        previous,
        slideCount,
    }) => {
        const arrowLeftClasses = classNames(styles.arrowLeft, {
            [styles.disabled]: current === 0,
        });
        const arrowRightClasses = classNames(styles.arrowRight, {
            [styles.disabled]: current + 1 === slideCount,
        });

        return (
            <div className={ styles.arrowsContainer }>
                <Arrow
                    onClick={ previous }
                    className={ arrowLeftClasses } />
                <Arrow
                    onClick={ next }
                    className={ arrowRightClasses } />
            </div>
        );
    };

    const renderSlides = () => photos.map(({ description, url }, index) => (
        <div className={ styles.imageWrapper } key={ `slider-${index}` }>
            <ContentfulImage
                src={ url }
                alt={ description }
                className={ styles.image } />
        </div>
    ));

    return (
        <BaseCarousel
            arrows
            draggable
            offset={ offset }
            renderArrows={ renderArrows }
            wrapperClassName={ className }
            sliderClassName={ styles.slider }
            carouselClassName={ styles.carousel }
            disableNativeScroll={ !isTouchDevice() }
            slideSnapDuration={ SLIDE_SNAP_DURATION }
            slideTransitionDuration={ SLIDE_TRANSITION_DURATION }
            { ...remainingProps }>
            { renderSlides() }
        </BaseCarousel>
    );
};

Carousel.propTypes = {
    className: PropTypes.string,
    photos: PropTypes.array.isRequired,
};

export default Carousel;
