import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Observer, ScaleAndFadeAnimation } from '../../../components';

import styles from './ContentSection.module.css';

export const ContentSection = ({
    text,
    title,
    children,
    className,
    withAnimation,
}) => {
    const sectionClasses = classNames(styles.section, className);

    if (withAnimation) {
        return (
            <Observer threshold={ 0.2 }>
                { (isVisible) => (
                    <section className={ sectionClasses }>
                        <ScaleAndFadeAnimation startAnimation={ isVisible }>
                            <div className={ styles.wrapper }>
                                <h2 className={ styles.title }>{ title }</h2>
                                { text && <p className={ styles.text }>{ text }</p> }
                            </div>
                        </ScaleAndFadeAnimation>
                        { !!children && children }
                    </section>
                ) }
            </Observer>
        );
    }

    return (
        <section className={ sectionClasses }>
            <div className={ styles.wrapper }>
                <h2 className={ styles.title }>{ title }</h2>
                { text && <p className={ styles.text }>{ text }</p> }
            </div>
            { !!children && children }
        </section>
    );
};

ContentSection.propTypes = {
    text: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    withAnimation: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

ContentSection.defaultProps = {
    withAnimation: false,
};

export default ContentSection;
